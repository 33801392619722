<template>
	<div class="flash-deals">
		<div class="flash-deals__header big-wrapper big-wrapper--padding">
			<div class="flash-deals__title">
				<span class="flash-deals__icon icon-thunder"></span>
				<h2 class="flash-deals__text">{{ label }}</h2>
			</div>
			<div class="flash-deals__timer">
				<flash-deals-timer :end-time="endTime" />
			</div>
			<nuxt-link :to="localeLink('store/flashdeals')" class="flash-deals__navigation">{{ $t("store.view_all") }}</nuxt-link>
		</div>
		<div class="flash-deals__content big-wrapper">
			<div v-swiper:mySwiper="swiperOption" id="product-flashdeals-container" ref="product-flashdeals-slider">
				<div class="swiper-wrapper">
					<div class="swiper-slide" v-for="(card, index) in cards" :key="card.id">
						<store-preview-card
							:preview-deal="card"
							:position="index + 1"
							:showFacilities="true"
							:tracking="tracking"
						/>
					</div>
				</div>
				<div class="swiper-button swiper-button-prev" slot="button-prev"></div>
				<div class="swiper-button swiper-button-next" slot="button-next"></div>
			</div>
		</div>
	</div>
</template>

<script>
import FlashDealsTimer from "@/components/store/flashDeals/timer/index.vue";
import StorePreviewCard from "@/components/cards/storePreview/index.vue";

import { observeSwiper } from '@/helpers/trackingHelper';

import '~/plugins/swiper';

export default {
	name: "flash-deals",
	props: {
		label: {
			type: String,
			required: true
		},
		description: {
			type: String,
			required: false
		},
		cards: {
			type: Array,
			required: true
		},
		endTime: {
			type: String,
			required: true
		},
		trackingCurrentScreen: {
			type: String,
			required: false
		}
	},
	components: {
		"flash-deals-timer": FlashDealsTimer,
		"store-preview-card": StorePreviewCard
	},
	data() {
		return {
			swiperOption: {
				slidesPerView: "auto",
				spaceBetween: 20,
				slidesOffsetBefore: 80,
				slidesOffsetAfter: 80,
				navigation: {
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev"
				},
				breakpoints: {
					767: {
						spaceBetween: 10,
						slidesOffsetBefore: 14,
						slidesOffsetAfter: 14,
						navigation: false
					}
				}
			},
			tracking: {
				currentScreen: this.trackingCurrentScreen,
				section: 'flash_sale',
				entityType: 'product_impression',
				type: 'ctr'
			}
		}
	},
	methods: {
		handleTracking() {
			observeSwiper({
				context: this,
				type: this.tracking.type,
				containerElementId: 'product-flashdeals-container',
				listElementIdentifier: '.card[data-tracking-id]',
				swiperRefs: this.$refs['product-flashdeals-slider'].swiper,
				trackingMeta: {
					current_screen: this.tracking.currentScreen,
					section: this.tracking.section
				}
			});
		}
	},
	mounted() {
		this.$nextTick(() => { this.mySwiper.el.style.opacity = 1 });
		this.handleTracking();
	}
}
</script>

<style lang="scss" src="./style.scss" scoped></style>