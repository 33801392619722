<template>
	<div class="store-preview big-wrapper">
		<div class="store-preview__header">
			<div class="store-preview__header__first-row">
				<h2 class="store-preview__title">{{ label }}</h2>
				<nuxt-link v-if="showViewAll && viewAllLink" class="store-preview__navigation" @click.native="trackViewButton" :to="localeLink(viewAllLink)">{{ $t("store.view_all") }}</nuxt-link>
			</div>
			<p class="store-preview__subtitle" v-if="description">{{ description }}</p>
		</div>
		<div class="store-preview__content">
			<div v-swiper:mySwiper="swiperOption" :id="swiperId" :ref="swiperRef" :data-swiper-ref="swiperRef">
				<div class="swiper-wrapper">
					<div class="swiper-slide" v-for="(card, index) in cards" :key="card.id">
						<store-preview-card
							v-if="cardStyle === 'compact'"
							:section="section"
							:preview-deal="card"
							:position="index + 1"
							:list="list"
							:tracking="trackingCurrentScreen ? tracking : undefined"
						/>
						<store-detail-preview-card v-if="cardStyle === 'detailed'"
							dealType="other"
							type="list"
							:deal="card"
							:position="index + 1"
							:list="list"
							:section="section"
						/>
					</div>
				</div>
				<div class="swiper-button swiper-button-prev" :class="cardStyle === 'detailed' ? 'swiper-button--detailed' : ''" slot="button-prev"></div>
				<div class="swiper-button swiper-button-next"  :class="cardStyle === 'detailed' ? 'swiper-button--detailed' : ''" slot="button-next"></div>
			</div>
		</div>
	</div>
</template>

<script>
import StorePreviewCard from "@/components/cards/storePreview/index.vue";
import StoreDetailPreviewCard from "@/components/cards/deal/index.vue";

import { observeSwiper, observeList, postTracking } from '@/helpers/trackingHelper';
import { scrollStop } from '@/helpers/scroll';
import { stringToSlug } from '@/helpers/stringHelper';
import impressionHelper from "@/helpers/impressionHelper";

import '~/plugins/swiper';

export default {
	name: "StorePreview",
	mixins: [impressionHelper],
	components: {
		StorePreviewCard,
		StoreDetailPreviewCard
	},
	props: {
		viewActionLabel: {
			type: String,
			required: false,
			default: ''
		},
		productId: {
			type: Number,
			required: false,
			default: 0
		},
		vendorId: {
			type: Number,
			required: false,
			defaut: 0,
		},
		vendorSlug: {
			type: String,
			required: false,
			default: ''
		},
		label: {
			type: String,
			required: true
		},
		description: {
			type: String,
			required: false
		},
		cards: {
			type: Array,
			required: true
		},
		viewAllLink: {
			type: String,
			required: false
		},
		cardStyle: {
			type: String,
			required: false,
			default: 'compact',
			validator(value) {
				return ['compact', 'detailed'].indexOf(value) !== -1
			}
		},
		list: null,
		section: null,
		showViewAll: {
			type: Boolean,
			required: false,
			default: true
		},
		observe: {
			type: Boolean,
			required: false,
			default: false
		},
		trackingSection: {
			type: String,
			required: false
		},
		trackingCurrentScreen: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			swiperOption: {
				slidesPerView: "auto",
				spaceBetween: 20,
				slidesOffsetBefore: 80,
				slidesOffsetAfter: 80,
				navigation: {
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev"
				},
				breakpoints: {
					767: {
						spaceBetween: 10,
						slidesOffsetBefore: 14,
						slidesOffsetAfter: 14,
						navigation: false
					}
				}
			},
			tracking: {
				currentScreen: this.trackingCurrentScreen,
				section: stringToSlug(this.label, '_'),
				entityType: 'product_impression',
				type: 'ctr'
			},
			trackingData: []
		}
	},
	computed: {
		swiperId() {
			return `${stringToSlug(this.label)}-container`;
		},
		swiperRef() {
			return `${stringToSlug(this.label)}-slider`;
		}
	},
	methods: {
		trackViewButton() {
			if(this.viewActionLabel) {
				window.dataLayer = window.dataLayer || []
				window.dataLayer.push({
					event: "pdp_other_product",
					pdpOtherProductProperties: {
						action: this.viewActionLabel,
						productId: this.productId,
						productSlug: this.$nuxt.$route.path,
						vendorId: this.vendorId,
						vendorSlug: this.vendorSlug,
					}
				})
			}
		},
		handleSwiperTracking() {
			observeSwiper({
				context: this,
				type: this.tracking.type,
				containerElementId: this.swiperId,
				listElementIdentifier: '.card[data-tracking-id]',
				swiperRefs: this.$refs[this.swiperRef].swiper,
				trackingMeta: {
					current_screen: this.tracking.currentScreen,
					section: this.tracking.section
				}
			});
		},
		handleTrackingData({id, type, section, isEmpty=false}) {
			if (isEmpty) {
				this.trackingData.splice(0, this.trackingData.length);
			} else {
				this.trackingData.push({
					id: id,
					type: type,
					section: section
				});
			}
		},
		handleObserveList() {
			observeList({
				containerElementId: this.swiperId,
				listElementIdentifier: '.card[data-tracking-id]',
				callback: (params) => this.handleTrackingData(params)
			});
		},
		handlePostTracking() {
			postTracking({
				context: this,
				type: 'ctr',
				trackingData: this.trackingData,
				trackingMeta: {
					current_screen: this.trackingCurrentScreen
				},
				onSuccess: (params) => this.handleTrackingData(params)
			})
		},
		handleTracking() {
			setTimeout(()=>{
				this.handleObserveList();
				setTimeout(()=>{
					this.handlePostTracking();
				}, 500)

				scrollStop({
					onScrollStopCallback: () => { 
						this.handlePostTracking();
					}
				})
			}, 500);
		},
		handleAdsImpression() {
			this.validateImpressions({
				containerElementId: this.swiperId,
				listElementClass: '.card[data-adsid]',
				swiperRefs: this.$refs[this.swiperRef].swiper,
				impressionMeta: {
					screen: this.trackingCurrentScreen,
					page: 1
				}
			});
		}
	},
	mounted() {
		this.handleAdsImpression();

		if (this.observe) {
			this.handleTracking();
		}
		setTimeout(() => {
			this.handleSwiperTracking();
		}, 500);
	}
}
</script>

<style lang="scss" src="./style.scss" scoped></style>