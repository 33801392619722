<template>
	<div class="attribution big-wrapper">
		<div class="attribution__header">
			<h2 class="attribution__text attribution__text--title">{{$t('Deal.attribution_title')}}</h2>
		</div>
		<div class="attribution__content">
			<div v-swiper:mySwiper="swiperOption">
				<div class="swiper-wrapper">
					<div class="swiper-slide" v-for="card in cards" :key="card.id">
						<store-attribution-card :attribution="card" />
					</div>
				</div>
				<div class="swiper-button swiper-button-prev" slot="button-prev"></div>
				<div class="swiper-button swiper-button-next" slot="button-next"></div>
			</div>
		</div>
	</div>
</template>

<script>
const StoreAttributionCard = () => import("@/components/cards/attribution/index.vue");
import '~/plugins/swiper';

export default {
	name: "StoreAttribution",
	components: {
		StoreAttributionCard
	},
	props: {
		label: {
			type: String,
			required: true
		},
		cards: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			swiperOption: {
				slidesPerView: "auto",
				spaceBetween: 20,
				slidesOffsetBefore: 80,
				slidesOffsetAfter: 80,
				autoHeight: true,
				navigation: {
					nextEl: ".swiper-button-next",
					prevEl: ".swiper-button-prev"
				},
				breakpoints: {
					767: {
						spaceBetween: 10,
						slidesOffsetBefore: 14,
						slidesOffsetAfter: 14,
						navigation: false
					}
				}
			}
		}
	}
}
</script>

<style lang="scss" src="./style.scss" scoped></style>