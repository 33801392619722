<template>
    <div class="timer">
        <div class="timer__board timer__board--day">
            <span class="timer__value">{{ timestamp.days ? timestamp.days : '0' }}</span>
			<span class="timer__unit">{{$t('Deal.day')}}</span>
        </div>
        <div class="timer__board timer__board--hour">
            <span class="timer__value">{{ timestamp.hours ? timestamp.hours : '0' }}</span>
			<span class="timer__unit">{{$t('Deal.hours')}}</span>
        </div>
        <div class="timer__board timer__board--minute">
            <span class="timer__value">{{ timestamp.minutes ? timestamp.minutes : '0' }}</span>
			<span class="timer__unit">{{$t('Deal.minutes')}}</span>
        </div>
        <div class="timer__board timer__board--second">
            <span class="timer__value">{{ timestamp.seconds ? timestamp.seconds : '0' }}</span>
			<span class="timer__unit">{{$t('Deal.seconds')}}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "flash-deals-timer",
    props: {
        endTime: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            endTimeInMs: null,
            timestamp: {
                days: "00",
                hours: "00",
                minutes: "00",
                seconds: "00"
            },
            interval: null
        };
    },
    mounted() {
		this.endTimeInMs = new Date(this.endTime).getTime();

		if(this.isTimeExpired(this.endTimeInMs)) {
			return;
		}

		this.updateCountdown(this.endTimeInMs);
        this.interval = setInterval(() => {
            this.updateCountdown(this.endTimeInMs);
        }, 1000);
    },
    methods: {
        updateCountdown(endTimeInMs) {
            if (this.isTimeExpired(endTimeInMs)) {
                clearInterval(this.interval);
            }

            const now = new Date().getTime();
			const timeLeftTillExpire = endTimeInMs - now;

            this.formatTimestamp(timeLeftTillExpire);
        },
        isTimeExpired(endTimeInMs) {
			const now = new Date().getTime();
			const timeLeft = endTimeInMs - now;
            return timeLeft <= 0 ? true : false;
        },
        formatTimestamp(timeInMs) {
            this.timestamp = this.transformTimestampToString(timeInMs);
            this.timestamp = this.padTimestamp(this.timestamp);
        },
        transformTimestampToString(timeInMs) {
            let { days, hours, minutes, seconds } = "00";
            days = Math.floor(timeInMs / (1000 * 60 * 60 * 24));
            hours = Math.floor(
                (timeInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            minutes = Math.floor((timeInMs % (1000 * 60 * 60)) / (1000 * 60));
            seconds = Math.floor((timeInMs % (1000 * 60)) / 1000);
            return { days, hours, minutes, seconds };
        },
        padTimestamp(timestamp) {
            for (let key in timestamp) {
                timestamp[key] = timestamp[key].toString().padStart(2, "0");
            }
            return timestamp;
        }
    }
};
</script>

<style lang="scss" src="./style.scss" scoped />