<template>
    <div class="seo-section-container" v-show="getSEOSection.length">
		<div class="big-wrapper big-wrapper--padding">
			<div class="row" v-for="(summary, index) in getSEOSection" :key="index">
				<div class="seo-section-content" v-if="summary.title === 'seo section'" v-html="sanitizeHtml(summary.content)"></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "SeoSection",
	computed: {
		getSEOSection() {
			return this.$store.getters.getSEOSection || []
		}
	}
};
</script>

<style lang="scss" src="./style.scss" scoped />