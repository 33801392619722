<template>
	<div>
		<div class="page__store" v-if="fetched" id="store-overview">
			<configurable-banner pageName="store_overview" :autoPlay="true"/>
			<a :href="localeLink('members/orders')" v-if="accountType && accountType == 'user'">
				<div class="order-button">
					<span class="imgs-menustore imgs-menustore--myorder"></span>
					&nbsp;
					<span class="order-button__text">{{ $t("header.my_order") }}</span>
				</div>
			</a>
			<div class="storemenu">
				<ul>
					<li>
						<nuxt-link :to="localeLink('store/products/all-inclusive-package')">
							<span class="imgs-menustore imgs-menustore--package"></span>
							<h3>{{ $t("Deal.wedding_package_title") }}</h3>
						</nuxt-link>
					</li>
					<li>
						<nuxt-link :to="localeLink('store/products/deals/venue')">
							<span class="imgs-menustore imgs-menustore--venue"></span>
							<h3>{{ $t("store.venue_deals") }}</h3>
						</nuxt-link>
					</li>
					<li>
						<nuxt-link :to="localeLink('store/products/deals')">
							<span class="imgs-menustore imgs-menustore--deals"></span>
							<h3>{{ $t("header.deals") }}</h3>
						</nuxt-link>
					</li>
					<li>
						<nuxt-link :to="localeLink('store/products')">
							<span class="imgs-menustore imgs-menustore--all"></span>
							<h3>{{$t('store.all_product')}}</h3>
						</nuxt-link>
					</li>
				</ul>
			</div>
			<flash-deals
				v-if="flashFeeds && flashFeeds.data"
				:label="flashFeeds.label"
				:description="flashFeeds.description"
				:cards="flashFeeds.data.deals"
				:endTime="flashFeeds.data.pagination.end_date"
				:trackingCurrentScreen="trackingCurrentScreen"
			/>
			<div class="preview-container">
				<store-preview
					v-for="(productFeed, index) in productFeeds"
					:key="productFeed.id"
					:label="productFeed.label"
					:description="productFeed.description"
					:cards="productFeed.data.deals"
					:section="productFeed.slug"
					:view-all-link="productFeed.viewAllLink"
					:list="index == 0? EECStore.store_overview_package: EECStore.store_overview_deal"
					:trackingCurrentScreen="trackingCurrentScreen"
				/>
			</div>
			<store-attribution :label="attributionFeeds.label" :cards="attributionFeeds.data.attributions" v-if="attributionFeeds && attributionFeeds.data"/>
			<store-listing 
				:titleName="$t('Deal.product_title')"
				:showFilter="false"
				:positionInTitle="true"
				:fetchAPI="dataAPI"
				:initFetchOnFilter="true"
				:list="EECStore.products_you_may_like"
				:pushDataLayer="pushDataLayer"
				:trackingCurrentScreen="trackingCurrentScreen"
				:trackingSection="trackingSection($t('Deal.product_title'))"
				:limit="limit"
				:ads="true"
				@observeList="handleObserveList"
				@postTracking="handlePostTracking"
				@observeAdsImpression="handleAdsImpression"
			/>
			<seo-section />
			<configured-popup pageName="store_overview" v-if="showConfiguredPopup"/>
		</div>
	</div>
</template>

<script>
import BannerSlider from "@/components/store/bannerSlider/index.vue";
import ConfBannerSlider from "@/components/bannerSlider/index.vue";
import FlashDeals from "@/components/store/flashDeals/index.vue";
import StorePreview from "@/components/store/preview/index.vue";
import StoreAttribution from "@/components/store/attribution/index.vue";
import StoreListing from "@/components/store/listing/index.vue";
import StoreHeader from "@/components/store/header/index.vue";
import SeoSection from "@/components/footer/seoSection/index.vue";
const ConfiguredPopup = () => import('~/components/popup/configured/index.vue');

import storeAPI from "@/api/store";
import dealsAPI from "@/api/deals";
import miscAPI from "@/api/misc";

import seo from "@/helpers/seoHelper";
import localStorageProvider from '@/helpers/localStorageProvider';
import { EEC_LIST } from "@/helpers/constants";
import { observeList, postTracking } from '@/helpers/trackingHelper';
import { scrollStop } from '@/helpers/scroll';
import impressionHelper from "@/helpers/impressionHelper";
import { stringToSlug } from '@/helpers/stringHelper';

import Cookie from "js-cookie";

export default {
	name: "LandingStore",
	layout: "defaultBridestory",
	components: {
		"banner-slider": BannerSlider,
		"flash-deals": FlashDeals,
		"store-preview": StorePreview,
		"store-attribution": StoreAttribution,
		"store-listing": StoreListing,
		"store-header": StoreHeader,
		"seo-section": SeoSection,
		"configured-popup": ConfiguredPopup,
		"configurable-banner" : ConfBannerSlider,
	},
	middleware: ["previousPage"],
	mixins: [seo, localStorageProvider, impressionHelper],
	data() {
		return {
			widthBrowser: 0,
			isSelected: "all",
			dataAPI: null,
			banners: null,
			showConfiguredPopup: false,
			trackingData: [],
			trackingCurrentScreen: 'store_overview',
			limit: this.$device.isDesktop ? 16 : 12
		}
	},
	computed: {
		flashFeeds() {
			return this.$store.state.storeHome.flash || []
		},
		productFeeds() {
			return this.$store.state.storeHome.product || []
		},
		attributionFeeds() {
			return this.$store.state.storeHome.attribution || []
		},
		accountId() {
			let account = this.$store.state.account.account

			if (account) return account.accountId
			else return null
		},
		accountType(){
			let account = this.$store.state.account.account

			if (account) return account.type
			else return null
		},
		EECStore(){
			return EEC_LIST
		},
		fetched() {
			return this.$store.state.storeHome.isLoading ? false : true;
		}
	},
	methods:{
		trackingSection(value) {
			return stringToSlug(value, '_');
		},
		pushDataLayer(deals, list){
			let self = this
			setTimeout(function(){
				let _products = []

				for(let i = 0; i < deals.length; i++ ){
					_products[i]={
						id: deals[i].id.toString(),
						name: deals[i].title || '',
						category: deals[i].category.name,
						price: deals[i].is_discount ? deals[i].discounted_price : deals[i].normal_price || 0,
						list: list,
						position: i + 1,
						dimension1: deals[i].vendor.id,
						dimension2: deals[i].vendor.name,
						dimension3: self.accountType,
						dimension4: self.accountId,
						currency: deals[i].currency.display
					}
				}

				self.ecommercePush('PRODUCT_IMPRESSIONS', _products)
			},3000)
		},
		setPrevPage(page) {
			Cookie.set('BS.previous-page', page, {domain : '.bridestory.com', path : '/'});
		},
		handleTrackingData({id, type, section, page, isEmpty=false}) {
			if (isEmpty) {
				this.trackingData.splice(0, this.trackingData.length);
			} else {
				this.trackingData.push({
					id: id,
					type: type,
					section: section,
					page: page
				});
			}
		},
		handleObserveList() {
			observeList({
				containerElementId: 'store-overview',
				listElementIdentifier: '[data-tracking-id]',
				callback: (params) => this.handleTrackingData(params)
			});
		},
		handlePostTracking() {
			postTracking({
				context: this,
				type: 'ctr',
				trackingData: this.trackingData,
				trackingMeta: {
					current_screen: this.trackingCurrentScreen
				},
				onSuccess: (params) => this.handleTrackingData(params)
			})
		},
		handleTracking() {
			setTimeout(()=>{
				this.handleObserveList();
				setTimeout(()=>{
					scrollStop({
						onScrollStopCallback: () => { 
							this.handlePostTracking();
						}
					});
				}, 500);
			}, 500);
		},
		handleAdsImpression() {
			this.validateImpressions({
				containerElementId: 'store-listing',
				listElementClass: '.card[data-adsid]',
				impressionMeta: {
					screen: this.trackingCurrentScreen
				}
			});
		}
	},
	beforeMount() {
		let dealsApi = new dealsAPI(this.$axios)
		this.dataAPI = (filter) => {
			filter["status"] = "all"
			return dealsApi.getAll(filter)
		}

		this.pageView({
			v2_pageProperties: {
				category: 'store_home',
				subCategory: undefined
			}
		})

		this.setLocalStorage('page.name', "StorePage");
		this.dataLayerPageName("StorePage");
		this.setPrevPage('store_home');

		const cookies = {
			localization: this.$cookies.get("BS_localization") || "ID",
			translation: this.$cookies.get("BS_PreferredLang") || "en"
		}

		const params = {
			status: "active",
			isMobile: true,
			isDeal: true
		}

		let MiscApi = new miscAPI(this.$axios)
		MiscApi.getBanners(params, cookies).then(res => {
			this.banners = res.data.banners;

			for (let banner of this.banners) {
				window.dataLayer = window.dataLayer || [];
				window.dataLayer.push({
					event: 'v2_banner_load',
					v2_bannerId: banner.title + ';' + banner.id
				});
			}

			let pageLimit = function () {
				if (window.innerWidth < 768) {
					return 4;
				} else if (window.innerWidth < 1025) {
					return 6;
				} else if (window.innerWidth < 1441) {
					return 8;
				} else if (window.innerWidth < 2561) {
					return 12;
				}
			}
			this.$nuxt.$loading.finish();
		}).catch(e => {
			this.$nuxt.$loading.finish();
		})

		if(window.innerWidth < 1024 || document.documentElement.clientWidth < 1024 || document.body.clientWidth < 1024) this.$store.dispatch('setStoreListPage');
	},
	mounted() {
		this.widthBrowser = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

		window.addEventListener("scroll", () => {this.showConfiguredPopup = true });
		if(this.flashFeeds && this.flashFeeds.data) this.pushDataLayer(this.flashFeeds.data.deals, EEC_LIST.store_overview_flash)
		if(this.productFeeds[0]) this.pushDataLayer(this.productFeeds[0].data.deals, EEC_LIST.store_overview_package)
		if(this.productFeeds[1]) this.pushDataLayer(this.productFeeds[1].data.deals, EEC_LIST.store_overview_deal)

		this.handleTracking();
	},
	beforeDestroy() {
		window.removeEventListener('scroll', () => {this.showConfiguredPopup = true });
		if(window.innerWidth < 1024 || document.documentElement.clientWidth < 1024 || document.body.clientWidth < 1024) this.$store.dispatch('setStoreListPage');
	},
	async fetch({ store, $axios, error, req, route }) {
		try {
			let storeApi = new storeAPI($axios)
			let { data } = await storeApi.getHomeData({ tag: 'v3' });

			store.dispatch("setStoreHome", data.homefeeds);
			store.dispatch("setSelectedCategory", null);
			store.dispatch("setSelectedSubCategory", null);
		} catch (err) {
			console.error(err);
		}
	}
}
</script>

<style lang="scss" src="./style.scss" scoped />