<template>
	<span class="rating-wrapper">
		<span :class="['rating', className]">
			<i v-for="(rate, i) in rateArray" :key="i" :class="['icon-star-filled', calcRating(i, rating)]" />
		</span>
		<a class='f-g-i f-g-11 rev-link' v-if="rating > 0" target="_blank" :href="reviewLink">
			<span itemProp="reviewCount" :content="review">{{ review }}</span>
		</a>
		<span itemProp="ratingValue" :content="rating" />
		<span itemProp="worstRating" content="0"></span>
  		<span itemProp="bestRating" content="5"></span>
	</span>
</template>

<script>
export default {
	name: "ratingLego",
	props: ["rating", "review", "name", "slug"],
	data() {
		return {
			rateArray: [1,2,3,4,5],
			className: ''
		}
	},
	computed: {
		reviewLink(){
			let path = this.localeLink(`${this.slug}?tab=reviews`).slice(1)
			return process.env.BASE_URL + path
		}
	},
	methods: {
		calcRating(index, rating) {
			return (index < rating ? '' : index >= rating ? 'icon-star-filled grey' : 'icon-star-half')
		}
	}
};
</script>

<style lang="scss" src="./style.scss" scoped />